import React from "react";

function Contact() {
    return (
        <>
            <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                {/* <button type="button" className="btn btn-primary btn-lg px-4 gap-3">Primary button</button> */}
                <a href="https://www.fiverr.com/sulimanmukhtar" target="_blank" rel="noreferrer" className="btn btn-outline-secondary btn-lg px-4">Contact us - Fiverr</a>
            </div>
        </>
    );
}

export default Contact;