import React, { Component } from 'react';
import { Link } from 'react-router-dom'


class Navbar extends Component {
    // constructor(props) {
    //     super(props);
    // }
    // state = {}
    render() {
        return (

            <>
                <div className="container">
                    <header
                        className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4 border-bottom">
                        {/* <!-- <a href="/" className="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none">
                    <svg className="bi me-2" width="40" height="32" role="img" aria-label="Bootstrap">
                        <use xlink:href="#"></use>
                    </svg>
                     </a> --> */}

                        <a className="navbar-brand" href="/">
                            <img src='./logo.png' alt="..." width="100" />
                        </a>

                        <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
                            <Link to='/'><li className="nav-link px-2 link-secondary">Home</li></Link>
                            <Link to='/Services'><li className="nav-link px-2 link-dark">Services</li></Link>
                            <Link to='/About'><li className="nav-link px-2 link-dark">About</li></Link>
                            <Link to='/Contact'><li className="nav-link px-2 link-dark">Contact US</li></Link>
                            <Link to='/FAQ'><li className="nav-link px-2 link-dark">FAQs</li></Link>
                        </ul>


                    </header>
                </div>
            </>
        );
    }
}

export default Navbar;