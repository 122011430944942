import { React, useRef, useEffect, useState } from "react";
import { ethers } from 'ethers'
import { css } from "@emotion/react";
import PropagateLoader from "react-spinners/PropagateLoader";




function Deploy() {

    let [loading, setLoading] = useState(false);
    // let [loading2, setLoading2] = useState(false);
    const override = css`
                        display: block;
                        margin: 0 auto;
                        border-color: red;
                        `;



    var DeployBTN = useRef("")
    // var DeployBTN2 = useRef("")
    var ConnectBTN = useRef("");
    // var Address = useRef("");
    // var Amount = useRef("");
    // var MintBTN = useRef("");
    var Alert = useRef("");
    // var Alert2 = useRef("");

    const [Feadback, setFeedback] = useState("");
    // const [Feadback2, setFeedback2] = useState("");

    var provider = new ethers.providers.Web3Provider(window.ethereum, "any");

    var signer = provider.getSigner();

    useEffect(() => {
        var provider = new ethers.providers.Web3Provider(window.ethereum, "any");

        const isMetaMaskConnected = async () => {

            const accounts = await provider.listAccounts();
            return accounts.length > 0;
        }

        console.log("test")
        if (window.ethereum) {



            isMetaMaskConnected().then(async (connected) => {
                if (connected) {

                    console.log("MetaMask is connected");

                } else {

                    console.log('Not Connected');
                    DeployBTN.current.style.display = "none";
                    ConnectBTN.current.style.display = "block";



                }
            });

            window.ethereum.on('disconnect', () => window.location.reload());
            window.ethereum.on('accountsChanged', () => window.location.reload());
            window.ethereum.on('chainChanged', () => window.location.reload());
        } else {
            // if no window.ethereum then MetaMask is not installed
            // MainForm.style.display = "none";


            DeployBTN.current.style.display = "none";
            // DeployBTN2.current.style.display = "none";
            // ConnectBTN.current.value = "block";

            // ConnectBTN.current.style.display = "block";




            // alert(
            //     "MetaMask is not installed. Please consider installing it: https://metamask.io/download.html"
            // );
        }
    }, [])









    const DeployContract = async () => {

        Alert.current.style.display = "none";

        setLoading(true);

        let account = await signer.getAddress()

        let gasPrice = await provider.getGasPrice() // gasPrice

        let contract = "";

        contract = await fetch("/config/contract.txt", {
            headers: {
                "Content-Type": "application/text",
                Accept: "application/text",
            },
        }).then(x => x.text());



        const tx = {
            from: account,
            value: ethers.utils.parseEther("0"),
            nonce: await provider.getTransactionCount(account, "latest"),
            gasPrice: gasPrice,
            data: contract
        }

        try {
            const transaction = await signer.sendTransaction(tx);
            await transaction.wait(1);
            setLoading(false);
            setFeedback("Contract Deployed Successfully");
            Alert.current.style.display = "block";
        } catch (e) {
            setFeedback(e.data.message);
            Alert.current.style.display = "block";
        }


        setLoading(false);
    }
    // const DeployContract2 = async () => {

    //     Alert.current.style.display = "none";

    //     setLoading(true);

    //     let account = await signer.getAddress()

    //     let gasPrice = await provider.getGasPrice() // gasPrice

    //     let contract = "";

    //     contract = await fetch("/config/presale.txt", {
    //         headers: {
    //             "Content-Type": "application/text",
    //             Accept: "application/text",
    //         },
    //     }).then(x => x.text());



    //     const tx = {
    //         from: account,
    //         value: ethers.utils.parseEther("0"),
    //         nonce: await provider.getTransactionCount(account, "latest"),
    //         gasPrice: gasPrice,
    //         data: contract
    //     }

    //     try {
    //         const transaction = await signer.sendTransaction(tx);
    //         await transaction.wait(1);
    //         setLoading(false);
    //         setFeedback("Contract Deployed Successfully");
    //         Alert.current.style.display = "block";
    //     } catch (e) {
    //         setFeedback(e.data.message);
    //         Alert.current.style.display = "block";
    //     }

    //     setLoading(false);
    // }


    const Connect = async () => {
        try {
            // switchNetwork();

            await provider.send("eth_requestAccounts", []);
            // getAccount();

        } catch (e) {
            if (e.code === 4001) {

                alert("Please Connect Your Wallet");

            } else if (e.code === -32002) {

                alert("Please Wait");

            }
        }

    }



    return (
        <>
            <div className="px-4 py-5 my-5 text-center">
                <img className="d-block mx-auto mb-4" src="./logo2.png" alt="" width="80" height="70" />
                <h1 className="display-5 fw-bold">Xroot Dev's</h1>
                <div className="col-lg-6 mx-auto" style={{ marginTop: 20 }}>
                    <p className="lead mb-4">We're here at <span style={{ fontSize: 25, color: '#012244', fontWeight: 650 }}>xrøøt</span> Developers Workspace are pleased to help you Create your tokens , NFT smart Contracts And various other Web , Blockchain development services .</p>

                </div>
            </div>


            <div className="w-full px-8 py-16 bg-white-100 xl:px-8" style={{ marginTop: -100 }}>
                <div className="max-w-5xl mx-auto" style={{ maxWidth: "500px" }} >
                    <div className="flex flex-col items-center md:flex-row" >

                        <div className="w-full App">
                            <div
                                className="relative z-10 h-auto p-8 py-10 overflow-hidden bg-white border-b-2 border-gray-300 rounded-lg shadow-2xl px-7">
                                <h3 className="mb-6 text-2xl font-medium text-center">Custom Deployment , DON'T Proceed</h3>
                                {/* <input ref={Name} type="text" name="name"
                                    className="block w-full px-4 py-3 mb-4 border border-2 border-transparent border-gray-200 rounded-lg focus:ring focus:ring-blue-500 focus:outline-none"
                                    placeholder="Token Name" />
                                <input ref={Symbol} type="text" name="symbol"
                                    className="block w-full px-4 py-3 mb-4 border border-2 border-transparent border-gray-200 rounded-lg focus:ring focus:ring-blue-500 focus:outline-none"
                                    placeholder="Token Symbol" /> */}
                                <div className="block">
                                    <button ref={ConnectBTN} onClick={Connect} className="w-full px-3 py-4 font-medium text-white bg-blue-600 rounded-lg"
                                        style={{ display: 'none' }}>Connect
                                        Metamask</button>
                                </div>
                                <div className="block App">
                                    <PropagateLoader color={"#000"} loading={loading} css={override} size={15} />
                                </div>
                                <div className="block" style={{ marginTop: "35px" }} >
                                    <button ref={DeployBTN} onClick={DeployContract}
                                        className="w-full px-3 py-4 font-medium text-white bg-blue-600 rounded-lg">Deploy TAZI Token</button>
                                </div>
                                {/* <div className="block" style={{ marginTop: "35px" }} >
                                    <button disabled ref={DeployBTN2} onClick={DeployContract2}
                                        className="w-full px-3 py-4 font-medium text-white bg-blue-600 rounded-lg">Deploy BoomBalle Presale-wait</button>
                                </div> */}
                                <div ref={Alert} className="bg-green-100 mt-2 rounded-lg py-3 px-6 mb-3 text-base text-green-700 inline-flex items-center w-full" style={{ display: "none" }}>
                                    <p>{Feadback}</p>
                                </div>

                                {/*<div style={{ marginTop: "70px" }} className="block">*/}
                                {/*    <input ref={Address} type="text"*/}
                                {/*        className="block w-full px-4 py-3 mb-4 border border-2 border-transparent border-gray-200 rounded-lg focus:ring focus:ring-blue-500 focus:outline-none"*/}
                                {/*        placeholder="Contract Address" />*/}
                                {/*    <input ref={Amount} type="text"*/}
                                {/*        className="block w-full px-4 py-3 mb-4 border border-2 border-transparent border-gray-200 rounded-lg focus:ring focus:ring-blue-500 focus:outline-none"*/}
                                {/*        placeholder="Amount" />*/}

                                {/*    <div className="block App">*/}
                                {/*        <PropagateLoader color={"#000"} loading={loading2} css={override} size={15} />*/}
                                {/*    </div>*/}

                                {/*    <div className="block" style={{ marginTop: "35px" }}>*/}
                                {/*        <button ref={MintBTN} onClick={() => Mint(Address.current.value, Amount.current.value)}*/}
                                {/*            className="w-full px-3 py-4 font-medium text-white bg-blue-600 rounded-lg">Mint</button>*/}
                                {/*    </div>*/}
                                {/*</div>*/}


                                {/*<div ref={Alert2} className="bg-green-100 mt-2 rounded-lg py-3 px-6 mb-3 text-base text-green-700 inline-flex items-center w-full" style={{ display: "none" }}>*/}
                                {/*    <p>{Feadback2}</p>*/}
                                {/*</div>*/}

                            </div>
                        </div>



                    </div>
                </div>
            </div>

        </>
    );
}

export default Deploy;
