import './App.css';
import Navbar from './Component/navbar'
// import Deploy from './Component/deploy'
import { Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
// import About from './Pages/About';
// import Services from './Pages/Services';
// import Contact from './Pages/Contact';
// import FAQ from './Pages/FAQ';
// import Deploy from './Pages/Deploy'
import Custom from './Pages/Custom'

console.log(process.env)
function App() {
  return (
    <div>
      <Navbar></Navbar>
      <Routes>
        {/* <Route exact path="/" element={<Home />} />
        <Route exact path="/About" element={<About />} />
        <Route exact path="/Services" element={<Services />} />
        <Route exact path="/Contact" element={<Contact />} />
        <Route exact path="/FAQ" element={<FAQ />} />
        <Route exact path="/Deploy" element={<Deploy />} />
        <Route exact path="/Custom" element={<Custom />} /> */}

        <Route exact path="/" element={<Home />} />
        <Route exact path="/About" element={<Home />} />
        <Route exact path="/Services" element={<Home />} />
        <Route exact path="/Contact" element={<Home />} />
        <Route exact path="/FAQ" element={<Home />} />
        {/*<Route exact path="/Deploy" element={<Deploy />} />*/}
        <Route exact path="/Custom" element={<Custom />} />
      </Routes>
      {/* <Deploy></Deploy> */}

    </div>


  );
}

export default App;
