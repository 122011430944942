import React from "react";
import Contact from "./Contact";

function Home() {
  return (
    <div>
      <div className="px-4 py-5 my-5 text-center">
        <img
          className="d-block mx-auto mb-4"
          src="./logo2.png"
          alt=""
          width="80"
          height="70"
        />
        <h1 className="display-5 fw-bold">Xroot Dev's</h1>
        <div className="col-lg-6 mx-auto" style={{ marginTop: 20 }}>
          <p className="lead mb-4">
            Welcome to Xroot Developers Workspace! We offer a variety of services including token creation and NFT smart contracts. Let us help you bring your ideas to life.
          </p>
          <p className="lead mb-4">
            Additionally, if you're interested in acquiring this domain name, feel free to reach out to us at <a href="mailto:xroot@xroot.dev">xroot@xroot.dev</a>. We also have the corresponding ENS domain available: xroot.eth.
          </p>
          <Contact />
        </div>
      </div>


      {/* <div className="container py-5">
        <h2 className="text-center mb-5">Our Services</h2>
        <div className="row row-cols-1 row-cols-md-3 mb-3 text-center">
          <div className="col">
            <div className="card mb-4 rounded-3 shadow-sm">
              <div className="card-header py-3">
                <h4 className="my-0 fw-normal">Token Creation</h4>
              </div>
              <div className="card-body">
                <p className="card-text">
                  Let us help you create your own custom tokens on the blockchain.
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card mb-4 rounded-3 shadow-sm">
              <div className="card-header py-3">
                <h4 className="my-0 fw-normal">Smart Contract Development</h4>
              </div>
              <div className="card-body">
                <p className="card-text">
                  Our team can help you create and deploy your own smart contracts.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <a style={{ float: 'right' }} href="https://www.digitalocean.com/?refcode=e40b058271c1&utm_campaign=Referral_Invite&utm_medium=Referral_Program&utm_source=badge"><img src="https://web-platforms.sfo2.digitaloceanspaces.com/WWW/Badge%202.svg" alt="DigitalOcean Referral Badge" /></a>
    </div>
    
  );
}

export default Home;
